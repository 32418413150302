import React from 'react'
import { motion } from 'framer-motion'

export default function Logotext() {
  const logoVariants = {
    animate: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  }

  const letterVariants = {
    initial: {
      pathLength: 0,
      y: -100,
      opacity: 0,
    },
    animate: {
      pathLength: 1,
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 0.8
      }
    }
  }
  
  return (
    <motion.svg variants={logoVariants} initial='initial' animate='animate'
      id="bab1daa9-30c1-473b-a9e1-072ea20e1a68" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269.9 319.6">
        <motion.polygon variants={letterVariants} points="47.5 133.4 47.5 131.6 16.6 131.6 48.1 84.1 5.2 84.1 5.2 85.8 32.8 85.8 1.2 133.4 47.5 133.4" fill="#4a5726"/>
        <motion.path variants={letterVariants} d="M93.1,128.6a33.9,33.9,0,0,0,10.4-14.4l.2-.4-1.6-.7-.2.5a25.3,25.3,0,0,1-7.7,10.5A17.8,17.8,0,0,1,83,127.9a16.2,16.2,0,0,1-12.9-5.6c-3.1-3.7-4.8-8.8-4.8-15.1a30.6,30.6,0,0,1,.2-3.6H99.1l.2-.3a15.1,15.1,0,0,0,.8-4.8A14.2,14.2,0,0,0,98,91a16,16,0,0,0-6.6-5.6,25.5,25.5,0,0,0-10.9-2.1,33.6,33.6,0,0,0-14.6,3.2,24.8,24.8,0,0,0-14.3,23,27.4,27.4,0,0,0,3,12.5,22.8,22.8,0,0,0,8.8,8.9,27.5,27.5,0,0,0,13.5,3.2A25,25,0,0,0,93.1,128.6ZM65.7,101.8c.8-4.8,2.6-8.7,5.6-11.6s7-4.5,12-4.5a18.5,18.5,0,0,1,8,1.7A13,13,0,0,1,96.5,92a11.8,11.8,0,0,1,1.9,6.6,11.3,11.3,0,0,1-.5,3.2Z" fill="#4a5726"/>
        <motion.path variants={letterVariants} d="M105,151.3a9.8,9.8,0,0,0,7.2,2.8,14.1,14.1,0,0,0,11-5.3,56.8,56.8,0,0,0,8.2-13.1l24.9-51.6h-2.2l-17.2,36.1L119.6,84.1H105.2L130,134.5a57.2,57.2,0,0,1-8.1,13c-2.9,3.1-6.1,4.8-9.7,4.8a7.9,7.9,0,0,1-5.9-2.3,9,9,0,0,1-2.1-6.2,15,15,0,0,1,1.3-5.8l.2-.5-1.8-.4v.4a15.6,15.6,0,0,0-1.5,6.3A10.5,10.5,0,0,0,105,151.3Z" fill="#4a5726"/>
        <motion.path variants={letterVariants} d="M166.6,121.8c0,4.2,1.1,7.3,3.1,9.3a11.7,11.7,0,0,0,8.4,3,20.5,20.5,0,0,0,11.5-3.6h.2v-1.9l-.7.5a10.4,10.4,0,0,1-4.8,1.4c-1.8,0-2.9-.5-3.6-1.7a9.3,9.3,0,0,1-1.1-4.7V85.8h9.3V84.1h-9.3V69.3h-1.1L166.9,84.1h-7.2v1.7h6.9Z" fill="#4a5726"/>
        <motion.polygon variants={letterVariants} points="196.4 133.4 209.3 133.4 209.3 83.3 196.4 85.4 196.4 133.4" fill="#4a5726"/>
        <motion.path variants={letterVariants} d="M202.8,78.1a7.5,7.5,0,0,0,5.6-2.3,7.9,7.9,0,0,0,2.3-5.6,7.7,7.7,0,0,0-7.9-7.7,7.7,7.7,0,0,0-7.7,7.7,7.9,7.9,0,0,0,7.7,7.9Z" fill="#4a5726"/>
        <motion.path variants={letterVariants} d="M266.2,87h0a15.9,15.9,0,0,0-10.6-3.7A23.5,23.5,0,0,0,243,87.1a38.2,38.2,0,0,0-10.2,9.6l-.6-13.4L220,85.4v48h13V99.5a35.5,35.5,0,0,1,8.1-8.6,14.5,14.5,0,0,1,8.4-3.1c2.5,0,4.3.6,5.4,1.7a6.4,6.4,0,0,1,2.1,3.7,26.4,26.4,0,0,1,.4,5.1v35.1h13V97.7C270.4,93,269,89.4,266.2,87Z" fill="#4a5726"/>
        <motion.path variants={letterVariants} d="M55,177.4c-4.4-2.7-10.2-4.1-17.4-4.1a32.6,32.6,0,0,0-15,3.2A11,11,0,0,0,16.3,187a9,9,0,0,0,2.1,6.2,9.7,9.7,0,0,0,4.6,3l.5.2.6-1.7-.5-.2a7.8,7.8,0,0,1-4.1-2.7,7.6,7.6,0,0,1-1.4-4.8,9.5,9.5,0,0,1,4.6-8.5,21.7,21.7,0,0,1,11.4-3c4.9,0,8.6,1.3,11,3.8s3.7,5.9,3.7,10.1v2.5a15.2,15.2,0,0,1-6.5,4.5,67.3,67.3,0,0,1-11.2,3c-4.2.8-7.5,1.6-10,2.3a15.5,15.5,0,0,0-6.3,3.8,9.4,9.4,0,0,0-2.6,7c0,3.8,1.3,6.7,4,8.7s6.5,2.9,11.4,2.9A24,24,0,0,0,41.7,220a23.2,23.2,0,0,0,7.4-8.6l1.8,12H61.8V191.8C61.8,185,59.5,180.2,55,177.4ZM40.3,218.3a12.4,12.4,0,0,1-6.9,2.1c-2.6,0-4.5-.8-5.6-2.4a9.8,9.8,0,0,1-1.7-6.1,12.6,12.6,0,0,1,1.5-6.7,9.1,9.1,0,0,1,3.8-3.5,35,35,0,0,1,6.5-2.4,44.6,44.6,0,0,0,6.9-2.4,12.5,12.5,0,0,0,4-2.6v12.4a20.1,20.1,0,0,1-3,6.3A19.8,19.8,0,0,1,40.3,218.3Z" fill="#4a5726"/>
        <motion.path variants={letterVariants} d="M118.1,163.4a6,6,0,0,1,4.4,1.6,5.5,5.5,0,0,1,1.9,4.1v.5l1.8-.2v-.5a7.6,7.6,0,0,0-2.3-5.1,8,8,0,0,0-5.7-2.1,8.2,8.2,0,0,0-6.1,2.2,8.1,8.1,0,0,0-2.2,6,13.3,13.3,0,0,0,1.6,6.2A34.2,34.2,0,0,0,97,173.3c-7.1,0-12.8,1.5-16.8,4.3S74,184.2,74,188.8a13.2,13.2,0,0,0,3,8.5,17.8,17.8,0,0,0,6.7,4.8,17.5,17.5,0,0,0-7.4,3.5,9.8,9.8,0,0,0-3.9,7.7c0,4.1,1.7,7,5.1,8.4s7.4,2.1,12.6,2.1h8.7l6.6-.2c4.8,0,8.3.6,10.5,1.9a6.1,6.1,0,0,1,3.1,5.7c0,3.8-2.3,6.7-6.9,8.8s-10.8,3.2-18.2,3.2-13.7-1.1-18-3.2-6.3-4.9-6.3-8.4a8.3,8.3,0,0,1,2.2-5.7l.4-.4-1.4-1-.2.3a9,9,0,0,0-2.8,6.8c0,4.3,2.5,7.7,7.5,10s11,3.3,18.6,3.3a54.9,54.9,0,0,0,16.3-2.2,25.9,25.9,0,0,0,11.6-6.8,15.3,15.3,0,0,0,4.3-11.1c0-9.3-6.8-14.1-20.3-14.1l-7.2.2H90a26.6,26.6,0,0,1-7.4-.8,2.8,2.8,0,0,1-2.2-2.8,3.1,3.1,0,0,1,1.8-2.7,13.5,13.5,0,0,1,5.1-1.4,36.4,36.4,0,0,0,9.7,1.1q10.6,0,16.8-4.2a13.4,13.4,0,0,0,.7-22.1,17,17,0,0,1-2.3-4.4,9.7,9.7,0,0,1-.7-3.7,6.1,6.1,0,0,1,6.6-6.5ZM86.7,188.8c0-9.2,3.4-13.7,10.3-13.7,3.4,0,5.9,1.1,7.6,3.4s2.7,5.8,2.7,10.3-.9,8.1-2.6,10.4-4.1,3.3-7.4,3.3-6.3-1.1-8-3.3S86.7,193.5,86.7,188.8Z" fill="#4a5726"/>
        <motion.path variants={letterVariants} d="M104.1,159.9a14.6,14.6,0,0,1-7.5,1.8,14.4,14.4,0,0,1-7.5-1.8,6.2,6.2,0,0,1-3.3-5v-.4H84.4v.5a19.1,19.1,0,0,0,3.4,11.2,10.5,10.5,0,0,0,8.8,4.3,10.3,10.3,0,0,0,8.7-4.4c2.2-2.8,3.3-6.5,3.4-11.1v-.5h-1.4v.4A6.1,6.1,0,0,1,104.1,159.9Z" fill="#4a5726"/>
        <motion.path variants={letterVariants} d="M173.2,177.4c-4.4-2.7-10.3-4.1-17.5-4.1a32.9,32.9,0,0,0-15,3.2,11.2,11.2,0,0,0-6.3,10.5,9,9,0,0,0,2.1,6.2,9.7,9.7,0,0,0,4.6,3l.5.2.6-1.7-.5-.2a7.8,7.8,0,0,1-4.1-2.7,8.3,8.3,0,0,1-1.4-4.8,9.5,9.5,0,0,1,4.6-8.5,22,22,0,0,1,11.4-3c4.9,0,8.6,1.3,11,3.8s3.7,5.9,3.7,10.1v2.5a14.6,14.6,0,0,1-6.5,4.5,67.3,67.3,0,0,1-11.2,3c-4.2.8-7.5,1.6-9.9,2.3a15.8,15.8,0,0,0-6.4,3.8,9.4,9.4,0,0,0-2.6,7,10.2,10.2,0,0,0,4.1,8.7c2.6,1.9,6.4,2.9,11.3,2.9a24,24,0,0,0,14.1-4.1,23.5,23.5,0,0,0,7.5-8.6l1.7,12h10.9V191.8C179.9,185,177.6,180.2,173.2,177.4Zm-14.7,40.9a12.7,12.7,0,0,1-7,2.1c-2.6,0-4.4-.8-5.6-2.4a10.5,10.5,0,0,1-1.7-6.1,12.6,12.6,0,0,1,1.5-6.7,9.1,9.1,0,0,1,3.8-3.5,39.6,39.6,0,0,1,6.6-2.4,46.9,46.9,0,0,0,6.8-2.4,11.5,11.5,0,0,0,4-2.6v12.4a18.1,18.1,0,0,1-3,6.3A19.5,19.5,0,0,1,158.5,218.3Z" fill="#4a5726"/>
        <motion.path variants={letterVariants} d="M238.9,203.6a27.1,27.1,0,0,1-7.7,10.5,18.1,18.1,0,0,1-11.3,3.8,16.2,16.2,0,0,1-12.8-5.6c-3.2-3.7-4.8-8.8-4.8-15.1s1.6-11.6,4.8-15.5,7.6-6,13.4-6,8.1,1.2,10.8,3.7a11.9,11.9,0,0,1,4.1,9.2,13.4,13.4,0,0,1-.8,4.1v.5l1.6.5v-.4a12.8,12.8,0,0,0,.9-4.8A14.2,14.2,0,0,0,235,181a15.7,15.7,0,0,0-6.7-5.6,25.1,25.1,0,0,0-10.8-2.1,33,33,0,0,0-14.6,3.2,24.2,24.2,0,0,0-10.4,9.1,24.6,24.6,0,0,0-3.9,13.9,26.2,26.2,0,0,0,3,12.5,22.8,22.8,0,0,0,8.8,8.9,27.5,27.5,0,0,0,13.5,3.2,25.2,25.2,0,0,0,16.2-5.5,33.9,33.9,0,0,0,10.4-14.4v-.4l-1.5-.7Z" fill="#4a5726"/>
        <motion.polygon variants={letterVariants} points="248 223.4 260.9 223.4 260.9 173.3 248 175.4 248 223.4" fill="#4a5726"/>
        <motion.path variants={letterVariants} d="M69.6,298.4a21.3,21.3,0,0,1-6.5,8.9,15.1,15.1,0,0,1-9.4,3.2,13.7,13.7,0,0,1-10.9-4.7c-2.6-3.2-4-7.4-4-12.8a26.3,26.3,0,0,1,.1-3H67.4v-.3a10.7,10.7,0,0,0,.8-4.1,11.6,11.6,0,0,0-1.9-6.4,13.2,13.2,0,0,0-5.6-4.7,21.4,21.4,0,0,0-9.3-1.8,27.7,27.7,0,0,0-12.3,2.7,22.2,22.2,0,0,0-8.9,7.7A21.5,21.5,0,0,0,27,295a22.2,22.2,0,0,0,2.6,10.6,19.3,19.3,0,0,0,7.4,7.6,23.5,23.5,0,0,0,11.5,2.7,21.8,21.8,0,0,0,13.8-4.7A28.1,28.1,0,0,0,71.1,299l.2-.5-1.5-.6Zm-30.5-10a17.1,17.1,0,0,1,4.7-9.7A14,14,0,0,1,54,274.9a15.3,15.3,0,0,1,6.6,1.4,10.9,10.9,0,0,1,4.5,3.9,10.3,10.3,0,0,1,1.5,5.5,8.4,8.4,0,0,1-.4,2.7Z" fill="#ccbaa4"/>
        <motion.polygon variants={letterVariants} points="99.4 302.7 84.5 273.4 72.2 273.4 93.8 315.9 94.6 315.9 115.8 273.4 113.9 273.4 99.4 302.7" fill="#ccbaa4"/>
        <motion.polygon variants={letterVariants} points="121.9 315.3 133.1 315.3 133.1 254.2 121.9 256 121.9 315.3" fill="#ccbaa4"/>
        <motion.path variants={letterVariants} d="M183,298.4a23.1,23.1,0,0,1-6.5,8.9,15.5,15.5,0,0,1-9.5,3.2,13.5,13.5,0,0,1-10.8-4.7c-2.7-3.2-4.1-7.4-4.1-12.8,0-1.2.1-2.2.1-3h28.5l.2-.3a10.7,10.7,0,0,0,.7-4.1,12.4,12.4,0,0,0-1.8-6.4,12.9,12.9,0,0,0-5.7-4.7,21.3,21.3,0,0,0-9.2-1.8,28.2,28.2,0,0,0-12.4,2.7,22.4,22.4,0,0,0-9.6,30.2,18.9,18.9,0,0,0,7.5,7.6,23.2,23.2,0,0,0,11.4,2.7,21.6,21.6,0,0,0,13.8-4.7,29.1,29.1,0,0,0,8.9-12.2v-.5l-1.4-.6Zm-30.6-10a17.8,17.8,0,0,1,4.7-9.7,14.1,14.1,0,0,1,10.2-3.8,15.7,15.7,0,0,1,6.7,1.4,11.2,11.2,0,0,1,4.4,3.9,9.6,9.6,0,0,1,1.6,5.5,12.6,12.6,0,0,1-.4,2.7Z" fill="#ccbaa4"/>
        <motion.path variants={letterVariants} d="M224.3,275.5a10.1,10.1,0,0,0-7.4-2.8c-3.6,0-6.9,1.6-9.8,4.6a27,27,0,0,0-5.7,9.5l-.4-14.2-10.5,1.8v40.9h11.1V291.9a31.1,31.1,0,0,1,6-12.8c2.7-3.2,5.8-4.7,9.3-4.7s4.8.7,6.2,2.2a8.4,8.4,0,0,1,2.3,6.1,12,12,0,0,1-1.9,6.4l-.3.4,1.4.9.3-.4a13.5,13.5,0,0,0,2.1-7.3,10.1,10.1,0,0,0-2.7-7.2Z" fill="#ccbaa4"/>
        <motion.path variants={letterVariants} d="M239.1,268.4a6.2,6.2,0,0,0,4.8-2,6.8,6.8,0,0,0,1.9-4.7,6.7,6.7,0,0,0-1.9-4.7,6.6,6.6,0,0,0-9.5,0,6.2,6.2,0,0,0-2,4.7,6.5,6.5,0,0,0,2,4.7A6.1,6.1,0,0,0,239.1,268.4Z" fill="#ccbaa4"/>
        <motion.polygon variants={letterVariants} points="233.5 315.3 244.6 315.3 244.6 272.6 233.5 274.5 233.5 315.3" fill="#ccbaa4"/>
        <motion.path variants={letterVariants} d="M82.2,4.1C78.5,1.8,73.5.6,67.4.6A27.8,27.8,0,0,0,54.6,3.3a9.6,9.6,0,0,0-5.3,9A7.3,7.3,0,0,0,51,17.5a8.4,8.4,0,0,0,4,2.6l.4.2.6-1.6h-.5a6.9,6.9,0,0,1-3.4-2.3,6.5,6.5,0,0,1-1.2-4,7.7,7.7,0,0,1,3.9-7.1,17.4,17.4,0,0,1,9.6-2.6c4.1,0,7.2,1.1,9.3,3.2a11.8,11.8,0,0,1,3.1,8.5v2.1a13.9,13.9,0,0,1-5.5,3.8,66.5,66.5,0,0,1-9.5,2.5,70.5,70.5,0,0,0-8.4,2A14.4,14.4,0,0,0,48,27.8a8.2,8.2,0,0,0-2.3,6.1,8.7,8.7,0,0,0,3.5,7.4c2.3,1.7,5.5,2.5,9.7,2.5a20.5,20.5,0,0,0,12-3.5,19.8,19.8,0,0,0,6.2-7.1l1.5,10h9.3V16.3C87.9,10.5,86,6.4,82.2,4.1ZM63.8,40.5a5.5,5.5,0,0,1-4.7-2,8.8,8.8,0,0,1-1.4-5.1,11.2,11.2,0,0,1,1.2-5.7,8.8,8.8,0,0,1,3.2-2.9,34.6,34.6,0,0,1,5.6-2,41,41,0,0,0,5.8-2.1,13.8,13.8,0,0,0,3.3-2V29a17.7,17.7,0,0,1-2.5,5.2,16.8,16.8,0,0,1-4.7,4.5A10.2,10.2,0,0,1,63.8,40.5Z" fill="#ccbaa4"/>
        <motion.polygon variants={letterVariants} points="118.2 30.6 103.3 1.2 91 1.2 112.6 43.8 113.4 43.8 134.6 1.2 132.7 1.2 118.2 30.6" fill="#ccbaa4"/>
        <motion.path variants={letterVariants} d="M168.8,19.2c-2.5-.9-5.8-1.9-9.8-3s-5.5-1.5-7.2-2.1a13.8,13.8,0,0,1-4.2-2.2,3.5,3.5,0,0,1-1.6-3c0-2,1.1-3.5,3.3-4.7s5.4-1.9,9.3-1.9,8.4.8,11.1,2.3,4,3.4,4,5.9a4.6,4.6,0,0,1-.8,2.9l-.4.5,1.6.8.2-.4a6.1,6.1,0,0,0,1-3.8c0-3-1.6-5.5-4.8-7.3S163.4.6,158.3.6a38.1,38.1,0,0,0-9.5,1.2,20.5,20.5,0,0,0-7.9,4.1,10,10,0,0,0-3.2,7.5,8.7,8.7,0,0,0,2.7,6.6,18.9,18.9,0,0,0,6.2,4c2.3.9,5.5,1.9,9.5,3.1l7.4,2.3a14.7,14.7,0,0,1,4.5,2.5,4.7,4.7,0,0,1,1.7,3.5q0,3.1-3.3,4.8c-2.3,1.3-5.9,1.9-10.5,1.9a31.4,31.4,0,0,1-9.1-1.2,15.9,15.9,0,0,1-6.2-3.4,6.7,6.7,0,0,1-2.1-4.9,6,6,0,0,1,.9-3.3l.3-.4-1.4-.9-.3.4a7.9,7.9,0,0,0-1.2,4.2q0,5.1,5.4,8.1c3.2,1.9,7.5,2.9,12.8,3.1l-1.6,3.3h.8a18.6,18.6,0,0,1,5.6.7,2.7,2.7,0,0,1,2,2.9,3.7,3.7,0,0,1-1.9,3.4,9.9,9.9,0,0,1-5.1,1.2,15.1,15.1,0,0,1-5.3-.9l-.3-.2-.8,1.2.3.3a13.7,13.7,0,0,0,4.2,2,22.5,22.5,0,0,0,5.2.7,15.1,15.1,0,0,0,7-1.6,5.5,5.5,0,0,0,3-5,5.4,5.4,0,0,0-4-5.4,24.5,24.5,0,0,0-8-1.2l.7-1.4a35.9,35.9,0,0,0,10.7-1.6,18.1,18.1,0,0,0,7.7-4.8,10.6,10.6,0,0,0,2.9-7.4,9,9,0,0,0-2.8-6.7A20.6,20.6,0,0,0,168.8,19.2Z" fill="#ccbaa4"/>
        <motion.path variants={letterVariants} d="M220.2,4.1C216.5,1.8,211.5.6,205.4.6a27.4,27.4,0,0,0-12.7,2.7,9.5,9.5,0,0,0-5.4,9,7.3,7.3,0,0,0,1.8,5.2,8,8,0,0,0,4,2.6l.4.2.6-1.6h-.5a6.5,6.5,0,0,1-3.4-2.3,6,6,0,0,1-1.3-4,7.7,7.7,0,0,1,3.9-7.1,17.8,17.8,0,0,1,9.7-2.6c4.1,0,7.2,1.1,9.2,3.2s3.1,4.9,3.1,8.5v2.1a13.5,13.5,0,0,1-5.4,3.8,66.5,66.5,0,0,1-9.5,2.5,72.9,72.9,0,0,0-8.5,2,13.3,13.3,0,0,0-5.3,3.1,8.2,8.2,0,0,0-2.3,6.1,8.7,8.7,0,0,0,3.5,7.4c2.2,1.7,5.5,2.5,9.6,2.5A20.6,20.6,0,0,0,209,40.3a20.8,20.8,0,0,0,6.2-7.1l1.4,10H226V16.3C226,10.5,224.1,6.4,220.2,4.1ZM201.9,40.5c-2.2,0-3.8-.7-4.7-2a8.1,8.1,0,0,1-1.5-5.1,11.2,11.2,0,0,1,1.3-5.7,8,8,0,0,1,3.2-2.9,33,33,0,0,1,5.5-2,42.9,42.9,0,0,0,5.9-2.1,15.5,15.5,0,0,0,3.2-2V29a15.5,15.5,0,0,1-2.5,5.2,16.4,16.4,0,0,1-4.6,4.5A10.4,10.4,0,0,1,201.9,40.5Z" fill="#ccbaa4"/>
    </motion.svg>
  )
}
